import React, { useCallback, useContext, useEffect } from 'react'
import { X } from 'react-feather'
import { useSpring } from 'react-spring/web'
import styled, { ThemeContext } from 'styled-components'
import { animated } from 'react-spring'
import { PopupContent } from '../../state/application/actions'
import { useRemovePopup } from '../../state/application/hooks'
import TransactionPopup from './TransactionPopup'
import NewNetworkPopup from './NewNetworkPopup'
import { DarkCard } from '../Card'
import { useWeb3React } from '@web3-react/core'

export const StyledClose = styled(X)`
	width: 16px;
	height: 16px;
	position: absolute;
	right: 10px;
	top: 10px;

	:hover {
		cursor: pointer;
	}
`
export const Popup = styled(DarkCard)`
	display: inline-block;
	width: 100%;
	padding: 1em;
	position: relative;
	border-radius: 10px;
	padding: 20px;
	padding-right: 35px;
	overflow: hidden;

	${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: 100%;
  `}
`
const Fader = styled.div`
	position: absolute;
	bottom: 0px;
	left: 0px;
	width: 100%;
	height: 2px;
	background-color: ${({ theme }) => theme.bg3};
`

const AnimatedFader = animated(Fader)

export default function PopupItem({
	removeAfterMs,
	content,
	popKey
}: {
	removeAfterMs: number | null
	content: PopupContent
	popKey: string
}) {
	const removePopup = useRemovePopup()
	const removeThisPopup = useCallback(() => removePopup(popKey), [popKey, removePopup])
	useEffect(() => {
		if (removeAfterMs === null) return undefined

		const timeout = setTimeout(() => {
			removeThisPopup()
		}, removeAfterMs)

		return () => {
			clearTimeout(timeout)
		}
	}, [removeAfterMs, removeThisPopup])

	const theme = useContext(ThemeContext)

	let popupContent
	const { txn, newNetworkChainId } = content
	if (txn) popupContent = <TransactionPopup hash={txn.hash} success={txn.success} summary={txn.summary} />
	else if (newNetworkChainId) popupContent = <NewNetworkPopup chainId={newNetworkChainId} />

	const faderStyle = useSpring({
		from: { width: '100%' },
		to: { width: '0%' },
		config: { duration: removeAfterMs ?? undefined }
	})
	const { account } = useWeb3React()

	return (
		<>
			{(account && newNetworkChainId) || txn ? (
				<Popup>
					<StyledClose color={theme.text2} onClick={removeThisPopup} />
					{popupContent}
					{removeAfterMs !== null ? <AnimatedFader style={faderStyle} /> : null}
				</Popup>
			) : (
				<div></div>
			)}
		</>
	)
}
