import React, { Suspense, lazy } from 'react'
import { Route, Switch, HashRouter } from 'react-router-dom'
import styled from 'styled-components'
import Header from '../components/Header'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'

const Stake = lazy(() => import('./Stake'))
const Stakeview = lazy(() => import('./Stake/inner'))
const Analysis = lazy(() => import('./Analysis'))

const AppWrapper = styled.div`
	display: flex;
	flex-flow: column;
	align-items: flex-start;
	overflow-x: hidden;
`

const HeaderWrapper = styled.div`
	${({ theme }) => theme.flexRowNoWrap}
	width: 100%;
	z-index: 4;
	height: 86px;
	justify-content: space-between;
`

const BodyWrapper = styled.div`
	display: flex;
	flex-direction: column;
	min-height: calc(100vh - 172px);
	width: 100%;
	padding-top: 60px;
	align-items: center;
	flex: 1;
	overflow-y: auto;
	overflow-x: hidden;
	z-index: 10;

	${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 16px;
    padding-top: 2rem;
  `};

	z-index: 1;
`

const Marginer = styled.div`
	margin-top: 5rem;
`

const Footer = styled.div`
	font-size: 12px;
	text-align: center;
	width: 420px;
	margin: auto;
	color: #fffa;
`

export default function App() {
	return (
		<Suspense fallback={null}>
			<HashRouter>
				<Route component={DarkModeQueryParamReader} />
				<AppWrapper>
					<HeaderWrapper>
						<Header />
					</HeaderWrapper>
					<BodyWrapper>
						<Popups />
						<Web3ReactManager>
							<Switch>
								<Route exact strict path="/" component={Stake} />
								<Route exact strict path="/stakeview" component={Stakeview} />
								<Route exact strict path="/analysis" component={Analysis} />
							</Switch>
						</Web3ReactManager>
						<Marginer />
					</BodyWrapper>

					<Footer>{new Date().getFullYear()} &copy; CridgeAI</Footer>
				</AppWrapper>
			</HashRouter>
		</Suspense>
	)
}
