import { ChainId } from 'dxswap-sdk'
import MULTICALL_ABI from './abi.json'



// TODO: Add the missing networks here
const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  // [ChainId.MAINNET]: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
  [ChainId.RINKEBY]: '0x42Ad527de7d4e9d9d011aC45B31D8551f8Fe9821',
  // [ChainId.ROPSTEN]: '0x20fE562d797A42Dcb3399062AE9546cd06f63280',
  [ChainId.MUMBAI_TESTNET]:'0x8C7d5bb8FB03a588cAEEd12e221Fe3e09f7fbA15',
  [ChainId.BSC_TESTNET]: '0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb',
  [ChainId.ARBITRUM_TESTNET_V3]: '0xb31E9e3446767AaDe9E48C4B1B6D13Cc6eDce172',
  [ChainId.SOKOL]: '0x8BE4eDB1930bdedd94142B09503eB6daC3F2b53D',
  [ChainId.XDAI]: '0x3990b44d6233D8287c62635028FAc7b046c5607A',
  [ChainId.MATIC]: '0x95028E5B8a734bb7E2071F96De89BABe75be9C8E',
  [ChainId.AVAX]: '0x82979a6f8D628270B29F5687bEA2F73D5D0eC77d',
  
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
