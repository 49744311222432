import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { useActiveWeb3React } from '../../hooks'
import { AppDispatch } from '../../state'
import { clearAllTransactions } from '../../state/transactions/actions'
import { shortenAddress } from '../../utils'
import { AutoRow } from '../Row'
import Copy from './Copy'
import Transaction from './Transaction'
import { useWeb3React } from '@web3-react/core'
import { ReactComponent as Close } from '../../assets/images/x.svg'
import { getExplorerLink } from '../../utils'
import { ExternalLink as LinkIcon } from 'react-feather'
import { ExternalLink, LinkStyledButton, TYPE } from '../../theme'

const HeaderRow = styled.div`
	${({ theme }) => theme.flexRowNoWrap};
	padding: 1rem 1rem;
	font-weight: 500;
	color: ${props => (props.color === 'blue' ? ({ theme }) => theme.primary1 : 'inherit')};
	${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem;
  `};
`

const UpperSection = styled.div`
	position: relative;
	background-color: ${({ theme }) => transparentize(0, theme.bg2)};

	h5 {
		margin: 0;
		margin-bottom: 0.5rem;
		font-size: 1rem;
		font-weight: 400;
	}

	h5:last-child {
		margin-bottom: 0px;
	}

	h4 {
		margin-top: 0;
		font-weight: 500;
	}
`

const InfoCard = styled.div`
	padding: 1rem;
	border: 1px solid ${({ theme }) => theme.bg3};
	border-radius: 8px;
	position: relative;
	display: grid;
	grid-row-gap: 12px;
	margin-bottom: 20px;
`

const AccountGroupingRow = styled.div`
	${({ theme }) => theme.flexRowNoWrap};
	justify-content: space-between;
	align-items: center;
	font-weight: 400;
	color: ${({ theme }) => theme.text1};

	div {
		${({ theme }) => theme.flexRowNoWrap}
		align-items: center;
	}
`

const AccountSection = styled.div`
	background-color: transparent;
	padding: 0rem 1rem;
	${({ theme }) => theme.mediaWidth.upToMedium`padding: 0rem 1rem 1.5rem 1rem;`};
`

const YourAccount = styled.div`
	h5 {
		margin: 0 0 1rem 0;
		font-weight: 400;
	}

	h4 {
		margin: 0;
		font-weight: 500;
	}
`

const LowerSection = styled.div`
	${({ theme }) => theme.flexColumnNoWrap}
	padding: 1.5rem;
	flex-grow: 1;
	overflow: auto;
	background-color: ${({ theme }) => transparentize(0.15, theme.bg1)};
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;

	h5 {
		margin: 0;
		font-weight: 400;
		color: ${({ theme }) => theme.text3};
	}
`

const AccountControl = styled.div`
	display: flex;
	justify-content: space-between;
	min-width: 0;
	width: 100%;

	font-weight: 500;
	font-size: 1.25rem;

	a:hover {
		text-decoration: underline;
	}

	p {
		min-width: 0;
		margin: 0;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
`

const AddressLink = styled(ExternalLink)<{ hasENS: boolean; isENS: boolean }>`
	font-size: 0.825rem;
	color: ${({ theme }) => theme.text4};
	margin-left: 1rem;
	font-size: 0.825rem;
	display: flex;
	:hover {
		color: ${({ theme }) => theme.text2};
	}
`

const CustomLinkIcon = styled(LinkIcon)`
	color: #03ff03;
`

const CloseIcon = styled.div`
	position: absolute;
	right: 1rem;
	top: 14px;
	&:hover {
		cursor: pointer;
		opacity: 0.6;
	}
`

const CloseColor = styled(Close)`
	width: 16px;
	height: 16px;
	color: ${({ theme }) => theme.text5};
`

const TransactionListWrapper = styled.div`
	${({ theme }) => theme.flexColumnNoWrap};
`

const WalletAction = styled.button`
	width: fit-content;
	font-weight: 700;
	font-size: 11px;
	letter-spacing: 0.08em;
	color: ${({ theme }) => theme.text1};
	background-color: ${({ theme }) => theme.bg3};
	padding: 8px 14px;
	outline: none;
	border: none;
	border-radius: 8px;

	:hover {
		cursor: pointer;
		background-color: #03ff03;
		color: #000;
	}
`

function renderTransactions(transactions: string[]) {
	return (
		<TransactionListWrapper>
			{transactions.map((hash, i) => {
				return <Transaction key={i} hash={hash} />
			})}
		</TransactionListWrapper>
	)
}

interface AccountDetailsProps {
	toggleWalletModal: () => void
	pendingTransactions: string[]
	confirmedTransactions: string[]
	ENSName?: string
	openOptions: () => void
}

export default function AccountDetails({
	toggleWalletModal,
	pendingTransactions,
	confirmedTransactions,
	ENSName,
	openOptions
}: AccountDetailsProps) {
	const { chainId, account, connector } = useActiveWeb3React()
	const dispatch = useDispatch<AppDispatch>()

	const clearAllTransactionsCallback = useCallback(() => {
		if (chainId) dispatch(clearAllTransactions({ chainId }))
	}, [dispatch, chainId])
	const { deactivate } = useWeb3React()
	const disConnect = () => {
		deactivate()
		//set flag to tell it has disconnect
		localStorage.setItem('is_cridgeai_connected', 'false')
	}
	return (
		<>
			<div id="account_popup">
				<UpperSection>
					<CloseIcon onClick={toggleWalletModal}>
						<CloseColor />
					</CloseIcon>
					<HeaderRow>
						<TYPE.body fontWeight={500} fontSize={20} color={'white'}>
							Account
						</TYPE.body>
					</HeaderRow>
					<AccountSection>
						<YourAccount>
							<InfoCard>
								<AccountGroupingRow>
									<div>
										<WalletAction
											onClick={() => {
												openOptions()
											}}
										>
											CHANGE WALLET
										</WalletAction>
										<WalletAction style={{ marginLeft: '15px' }} onClick={disConnect}>
											DISCONNECT WALLET
										</WalletAction>
									</div>
								</AccountGroupingRow>
								<AccountGroupingRow id="web3-account-identifier-row">
									<AccountControl>
										{ENSName ? (
											<>
												<div>
													<p> {ENSName}</p>
												</div>
											</>
										) : (
											<>
												<div>
													{' '}
													{account && (
														<TYPE.body fontSize="22px" fontWeight="500" color={'text1'}>
															{shortenAddress(account)}
														</TYPE.body>
													)}
												</div>
											</>
										)}
									</AccountControl>
								</AccountGroupingRow>
								<AccountGroupingRow>
									{ENSName ? (
										<>
											<AccountControl>
												<div>
													{account && (
														<Copy toCopy={account}>
															<span style={{ marginLeft: '4px' }}>Copy Address</span>
														</Copy>
													)}
													{chainId && account && (
														<AddressLink
															hasENS={!!ENSName}
															isENS={true}
															href={chainId && getExplorerLink(chainId, ENSName, 'address')}
														>
															<CustomLinkIcon size={16} />
															<span style={{ marginLeft: '4px' }}>View on block explorer</span>
														</AddressLink>
													)}
												</div>
											</AccountControl>
										</>
									) : (
										<>
											<AccountControl>
												<div>
													{account && (
														<Copy toCopy={account}>
															<span style={{ marginLeft: '4px' }}>Copy Address</span>
														</Copy>
													)}
													{chainId && account && (
														<AddressLink
															hasENS={!!ENSName}
															isENS={false}
															href={getExplorerLink(chainId, account, 'address')}
														>
															<CustomLinkIcon size={16} />
															<span style={{ marginLeft: '4px' }}>View on block explorer</span>
														</AddressLink>
													)}
												</div>
											</AccountControl>
										</>
									)}
								</AccountGroupingRow>
							</InfoCard>
						</YourAccount>
					</AccountSection>
				</UpperSection>
				{!!pendingTransactions.length || !!confirmedTransactions.length ? (
					<LowerSection>
						<AutoRow mb={'1rem'} style={{ justifyContent: 'space-between' }}>
							<TYPE.body color="text4">Recent Transactions</TYPE.body>
							<LinkStyledButton onClick={clearAllTransactionsCallback}>(clear all)</LinkStyledButton>
						</AutoRow>
						{renderTransactions(pendingTransactions)}
						{renderTransactions(confirmedTransactions)}
					</LowerSection>
				) : (
					<LowerSection>
						<TYPE.body>Your transactions will appear here...</TYPE.body>
					</LowerSection>
				)}
			</div>
		</>
	)
}
