import React from 'react'
import styled from 'styled-components'
import { darken, lighten, transparentize } from 'polished'

import { RowBetween } from '../Row'
import { ChevronDown } from 'react-feather'
import { Button as RebassButton, ButtonProps } from 'rebass/styled-components'
import border8pxRadius from '../../assets/images/border-8px-radius.png'
import { Text } from 'rebass'

const Base = styled(RebassButton)<{
	padding?: string
	width?: string
	borderRadius?: string
	altDisabledStyle?: boolean
}>`
	padding: ${({ padding }) => (padding ? padding : '18px')};
	width: ${({ width }) => (width ? width : '100%')};
	font-weight: 600;
	font-size: 13px;
	line-height: 16px;
	letter-spacing: 0.08em;
	text-transform: uppercase;
	text-align: center;
	border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '8px')};
	outline: none;
	border: none;
	color: ${({ theme }) => theme.white};
	text-decoration: none;
	display: flex;
	justify-content: center;
	flex-wrap: nowrap;
	align-items: center;
	cursor: pointer;
	position: relative;
	z-index: 1;
	&:disabled {
		cursor: auto;
		font-weight: 600;
		font-size: 13px;
		line-height: 16px;
		letter-spacing: 0.08em;
		text-transform: uppercase;
		text-align: center;
	}

	> * {
		user-select: none;
	}
`

export const ButtonPrimary = styled(Base)`
	background-color: #03ff03;
	color: #000000;
	transition: background-color 0.3s ease;
	&:hover {
		background-color: #009d00;
	}
	&:active {
		background-color: #009d00;
	}
	&:disabled {
		background-color: ${({ theme }) => theme.primary1};
		color: ${({ theme }) => transparentize(0.15, theme.purpleBase)};
		cursor: not-allowed;
		box-shadow: none;
		outline: none;
		opacity: ${({ altDisabledStyle }) => (altDisabledStyle ? '0.7' : '1')};
	}
`

export const ButtonSecondary = styled(Base)`
	background-color: #03ff03;
	color: #000000;
	background-color: transparent;
	font-size: 16px;
	border-radius: 8px;
	padding: ${({ padding }) => (padding ? padding : '10px')};

	&:disabled {
		opacity: 50%;
		cursor: auto;
	}
	a:hover {
		text-decoration: none;
	}
`

export const ButtonGrey = styled(Base)`
	border: 1px solid #252237;
	background: radial-gradient(147.37% 164.97% at 50% 0%, rgba(255, 255, 255, 0.1) 0%, rgba(0, 0, 0, 0) 100%), #1f1d2c;
	background-blend-mode: overlay, normal;
	color: ${({ theme }) => theme.text5};
	font-size: 16px;
	border-radius: 8px;
	padding: ${({ padding }) => (padding ? padding : '10px')};

	&:disabled {
		opacity: 50%;
		cursor: auto;
	}
	a:hover {
		text-decoration: none;
	}
`

export const ButtonDark = styled(Base)`
	border: 1px solid #252237;
	background-color: #03ff03;
	color: ${({ theme }) => theme.text5};
	font-size: 16px;
	border-radius: 8px;
	padding: ${({ padding }) => (padding ? padding : '10px')};

	&:disabled {
		opacity: 50%;
		cursor: auto;
	}
	a:hover {
		text-decoration: none;
	}
`

export const ButtonOutlined = styled(Base)`
	border: 8px solid;
	border-radius: 8px;
	border-image: url(${border8pxRadius}) 8;
	background-color: #03ff03;
	color: ${({ theme }) => theme.text1};
	text-transform: initial;
	cursor: pointer;
	&:disabled {
		opacity: 50%;
		cursor: not-allowed;
	}
`

export const ButtonEmpty = styled(Base)`
	background-color: transparent;
	color: ${({ theme }) => theme.primary1};
	display: flex;
	justify-content: center;
	align-items: center;

	&:focus {
		text-decoration: underline;
	}
	&:hover {
		text-decoration: underline;
	}
	&:active {
		text-decoration: underline;
	}
	&:disabled {
		opacity: 50%;
		cursor: auto;
	}
`

export const ButtonWhite = styled(Base)`
	border: 1px solid #edeef2;
	background-color: ${({ theme }) => theme.bg1};
	color: black;

	&:focus {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		box-shadow: 0 0 0 1pt ${darken(0.05, '#edeef2')};
	}
	&:hover {
		box-shadow: 0 0 0 1pt ${darken(0.1, '#edeef2')};
	}
	&:active {
		box-shadow: 0 0 0 1pt ${darken(0.1, '#edeef2')};
	}
	&:disabled {
		opacity: 50%;
		cursor: auto;
	}
`

const ButtonConfirmedStyle = styled(Base)`
	background-color: ${({ theme }) => lighten(0.5, theme.green1)};
	color: ${({ theme }) => theme.green1};
	border: 1px solid ${({ theme }) => theme.green1};

	&:disabled {
		opacity: 50%;
		cursor: auto;
	}
`

const ButtonErrorStyle = styled(Base)`
	background-color: ${({ theme }) => theme.red1};

	&:focus {
		box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.red1)};
		background-color: ${({ theme }) => darken(0.05, theme.red1)};
	}
	&:hover {
		background-color: ${({ theme }) => darken(0.05, theme.red1)};
	}
	&:active {
		box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.red1)};
		background-color: ${({ theme }) => darken(0.1, theme.red1)};
	}
	&:disabled {
		opacity: 50%;
		cursor: auto;
		box-shadow: none;
		background-color: ${({ theme }) => theme.red1};
	}
`

export function ButtonConfirmed({
	confirmed,
	altDisabledStyle,
	...rest
}: { confirmed?: boolean; altDisabledStyle?: boolean } & ButtonProps) {
	if (confirmed) {
		return <ButtonConfirmedStyle {...rest} />
	} else {
		return <ButtonPrimary {...rest} altDisabledStyle={altDisabledStyle} />
	}
}

export function ButtonError({ error, ...rest }: { error?: boolean } & ButtonProps) {
	if (error) {
		return <ButtonErrorStyle {...rest} />
	} else {
		return <ButtonPrimary {...rest} />
	}
}

export function ButtonWithLink({ link, text, marginTop }: { link: string; text: string; marginTop?: string }) {
	return (
		<ButtonSecondary
			id="join-pool-button"
			as="a"
			style={{ marginTop: marginTop ? marginTop : '0', padding: '10px 0px', borderRadius: '8px' }}
			href={link}
			rel="noopener noreferrer"
			target="_blank"
		>
			<Text fontWeight={700} fontSize={12} lineHeight="15px">
				{text} <span style={{ fontSize: '11px', marginLeft: '4px' }}>↗</span>
			</Text>
		</ButtonSecondary>
	)
}

export function ButtonDropdown({ disabled = false, children, ...rest }: { disabled?: boolean } & ButtonProps) {
	return (
		<ButtonPrimary {...rest} disabled={disabled}>
			<RowBetween>
				<div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
				<ChevronDown size={24} />
			</RowBetween>
		</ButtonPrimary>
	)
}

const StyledChevronDown = styled(ChevronDown)`
	color: ${({ theme }) => theme.text5};
`

export function ButtonDropdownLight({ disabled = false, children, ...rest }: { disabled?: boolean } & ButtonProps) {
	return (
		<ButtonOutlined {...rest} disabled={disabled}>
			<RowBetween>
				<div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
				<StyledChevronDown size={20} />
			</RowBetween>
		</ButtonOutlined>
	)
}
