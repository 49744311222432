import React, { useRef } from 'react'
import styled from 'styled-components'
import { ApplicationModal } from '../../state/application/actions'
import { useCloseModals, useModalOpen, useToggleMobileMenu } from '../../state/application/hooks'
// import { ExternalLink } from '../../theme'
import {
	// darken,
	transparentize
} from 'polished'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { Menu } from 'react-feather'
import Modal from '../Modal'
import { Box, Flex } from 'rebass'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import './Header.css'

const StyledMenu = styled.div`
	margin-left: 0.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	border: none;
	text-align: left;
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
	activeClassName
})`
	${({ theme }) => theme.flexRowNoWrap}
	align-items: left;
	outline: none;
	cursor: pointer;
	text-decoration: none;
	color: ${({ theme }) => theme.text5};
	width: fit-content;
	height: 36px;
	font-weight: 400;
	font-size: 16px;
	line-height: 19.5px;

	&.${activeClassName} {
		font-weight: 600;
		color: ${({ theme }) => theme.white};
	}
`

const HomeNavLink = styled.a`
	${({ theme }) => theme.flexRowNoWrap}
	align-items: left;
	outline: none;
	cursor: pointer;
	text-decoration: none;
	color: ${({ theme }) => theme.text5};
	width: fit-content;
	height: 36px;
	font-weight: 400;
	font-size: 16px;
	line-height: 19.5px;

	&.${activeClassName} {
		font-weight: 600;
		color: ${({ theme }) => theme.white};
	}
`
// const StyledExternalLink = styled(ExternalLink)`
//   ${({ theme }) => theme.flexRowNoWrap}
//   align-items: left;
//   border-radius: 3rem;
//   outline: none;
//   cursor: pointer;
//   text-decoration: none;
//   color: ${({ theme }) => theme.text5};
//   font-weight: 400;
//   font-size: 16px;
//   line-height: 19.5px;
//   height: 36px;

//   :hover,
//   :focus {
//     color: ${({ theme }) => darken(0.1, theme.text1)};
//   }
// `

const Wrapper = styled(Flex)`
	width: 100%;
	background: ${({ theme }) => transparentize(0.25, theme.bg2)};
`

export default function MobileOptions({ history }: { history: any }) {
	const node = useRef<HTMLDivElement>()
	const open = useModalOpen(ApplicationModal.MOBILE)
	const toggle = useToggleMobileMenu()
	const closeModals = useCloseModals()
	const { t } = useTranslation()
	useOnClickOutside(node, open ? toggle : undefined)

	return (
		<StyledMenu ref={node as any}>
			<Menu size={24} onClick={toggle} />
			<Modal isOpen={open} onDismiss={toggle}>
				<Wrapper flexDirection="column" p="16px 24px">
					<Box>
						<HomeNavLink href="https://cridgeai.com/" id="stake-nav-link" className="text-white">
							{t('Home')}
						</HomeNavLink>
						<StyledNavLink id={`stake-nav-link`} to={'/'} isActive={() => history.location.pathname.includes('/')}>
							{t('Stake')}
						</StyledNavLink>
						<StyledNavLink
							id={`stake-nav-link`}
							to={'/analysis'}
							isActive={() => history.location.pathname.includes('/analysis')}
						>
							{t('Analysis')}
						</StyledNavLink>
					</Box>
				</Wrapper>
			</Modal>
		</StyledMenu>
	)
}
